import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useSendClientLoginLinkMutation } from "@/graphql/mutations/sendClientLoginLink.graphql.types";
import useAction from "@/hooks/misc/useAction";

const EMAIL_FORM_SCHEMA = yup.object().shape({
  value: yup
    .string()
    .required("Please enter your email address")
    .email("Enter a valid email address (e.g., example@domain.com)"),
});

export const useClientPortalForm = () => {
  const [linkSent, setLinkSent] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const [sendClientLoginLink] = useSendClientLoginLinkMutation();

  const {
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { value: "" },
    resolver: yupResolver(EMAIL_FORM_SCHEMA),
    mode: "onTouched",
  });

  const { isLoading, handleAction } = useAction(async () => {
    try {
      const { data } = await sendClientLoginLink({
        variables: { email: watch("value") },
      });

      if (data?.sendClientLoginLink?.ok) {
        setLinkSent(true);
      }
    } catch (error) {
      setError(error);
    }
  });

  const resetForm = () => {
    reset({ value: "" });
    setLinkSent(false);
    setError(undefined);
  };

  return {
    control,
    setValue,
    errors,
    isValid,
    isLoading,
    linkSent,
    error,
    handleAction,
    resetForm,
  };
};

export default useClientPortalForm;
