// @ts-nocheck
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SendClientLoginLinkMutationVariables = Types.Exact<{
  email: Types.Scalars["String"]["input"];
}>;

export type SendClientLoginLinkMutation = {
  __typename?: "mutation_root";
  sendClientLoginLink?: {
    __typename?: "SendClientLoginLink";
    message?: string | null;
    ok?: boolean | null;
  } | null;
};

export const SendClientLoginLinkDocument = gql`
  mutation SendClientLoginLink($email: String!) {
    sendClientLoginLink(email: $email) {
      message
      ok
    }
  }
`;
export type SendClientLoginLinkMutationFn = Apollo.MutationFunction<
  SendClientLoginLinkMutation,
  SendClientLoginLinkMutationVariables
>;

/**
 * __useSendClientLoginLinkMutation__
 *
 * To run a mutation, you first call `useSendClientLoginLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClientLoginLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClientLoginLinkMutation, { data, loading, error }] = useSendClientLoginLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendClientLoginLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendClientLoginLinkMutation,
    SendClientLoginLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendClientLoginLinkMutation,
    SendClientLoginLinkMutationVariables
  >(SendClientLoginLinkDocument, options);
}
export type SendClientLoginLinkMutationHookResult = ReturnType<
  typeof useSendClientLoginLinkMutation
>;
export type SendClientLoginLinkMutationResult =
  Apollo.MutationResult<SendClientLoginLinkMutation>;
export type SendClientLoginLinkMutationOptions = Apollo.BaseMutationOptions<
  SendClientLoginLinkMutation,
  SendClientLoginLinkMutationVariables
>;
