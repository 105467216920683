import { Box, MenuItem, Menu, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { ChevronSmallDown } from "react-swm-icon-pack";
import { VIOLET } from "@/config/mui/colorPalette";
import { SignInUserRole } from "@/types/signIn";

const ROLE_CONFIG: Record<SignInUserRole, { displayText: string }> = {
  [SignInUserRole.Staff]: {
    displayText: "I am medspa staff",
  },
  [SignInUserRole.Client]: {
    displayText: "I am a client",
  },
};

type Props = {
  role: SignInUserRole;
  onChangeClick: () => void;
};

export default function OptionMenuMobile({ role, onChangeClick }: Props) {
  const displayText = ROLE_CONFIG[role]?.displayText;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRole = () => {
    handleClose();
    onChangeClick();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          gap: 1,
        }}
      >
        <Typography variant="labelSmall">{displayText}</Typography>
        <IconButton size="small" onClick={handleClick}>
          <ChevronSmallDown color={VIOLET[100]} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 1,
            sx: {
              borderRadius: 2,
              boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.16)",
              mt: 1.5,
              p: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleChangeRole}>Change Role</MenuItem>
      </Menu>
    </>
  );
}
