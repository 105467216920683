import { Box } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { PoweredByMoxie } from "@/components/common/powerByMoxie/powerByMoxie";
import ClerkSignIn from "@/components/signin/clerkSignIn";
import GetClientPortalLinkCard from "@/components/signin/getClientPortalLinkCard";
import MoxieLogoPageHeader from "@/components/signin/moxieLogoPageHeader";
import RoleSelector from "@/components/signin/roleSelector";
import { SignInUserRole, isValidRole } from "@/types/signIn";
import { clearDatadogUser } from "@/utils/datadog";
import useErrorLogger from "@/utils/useErrorLogger";

export const SELECTED_ROLE_COOKIE_KEY = "__moxie_sr";
const COOKIE_EXPIRATION_DAYS = 30;

type Props = {
  selectedRoleValueFromCookie: SignInUserRole;
};

export default function SignInView({ selectedRoleValueFromCookie }: Props) {
  const logError = useErrorLogger();

  const [userRole, setUserRole] = useState<SignInUserRole>(
    isValidRole(selectedRoleValueFromCookie)
      ? selectedRoleValueFromCookie
      : undefined
  );

  const handleRoleSelect = (
    selectedRole: SignInUserRole,
    shouldSaveAnswer: boolean
  ) => {
    if (!isValidRole(selectedRole)) {
      logError(
        "Invalid role selected. Please check RoleSelector implementation."
      );

      toast.error(
        "An error occurred while selecting your role. Please try again."
      );

      return;
    }
    setUserRole(selectedRole);
    if (shouldSaveAnswer) {
      Cookies.set(SELECTED_ROLE_COOKIE_KEY, selectedRole, {
        expires: COOKIE_EXPIRATION_DAYS,
        path: "/",
      });
    }
  };

  const onChangeClick = () => {
    Cookies.remove(SELECTED_ROLE_COOKIE_KEY, { path: "/" });
    setUserRole(undefined);
  };

  useEffect(() => {
    clearDatadogUser();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <MoxieLogoPageHeader
        title={
          userRole === SignInUserRole.Client ? "Client login" : "Provider login"
        }
        onChangeClick={onChangeClick}
        role={userRole}
      />
      <Box
        sx={{
          flex: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "safe center",
          overflow: "auto",
          padding: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        {!userRole ? (
          <RoleSelector onRoleSelect={handleRoleSelect} />
        ) : (
          <>
            {userRole === SignInUserRole.Staff && <ClerkSignIn />}
            {userRole === SignInUserRole.Client && <GetClientPortalLinkCard />}
          </>
        )}
      </Box>
      <PoweredByMoxie />
    </Box>
  );
}
