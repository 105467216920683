import {
  FormLabel,
  Checkbox,
  Box,
  Button,
  Card,
  Typography,
  useTheme,
} from "@mui/material";
import Image from "next/image";
import { useState } from "react";
import { GREY } from "@/config/mui/colorPalette";
import { SignInUserRole } from "@/types/signIn";
import { RoleCard } from "./roleCard";

type RoleSelectorProps = {
  onRoleSelect: (
    selectedRole: SignInUserRole,
    shouldSaveAnswer: boolean
  ) => void;
};

export default function RoleSelector({ onRoleSelect }: RoleSelectorProps) {
  const { palette } = useTheme();

  const [selectedRole, setSelectedRole] = useState<SignInUserRole>();
  const [shouldSaveAnswer, setShouldSaveAnswer] = useState(false);

  const handleRoleSelect = (role: SignInUserRole) => {
    setSelectedRole(role);
  };

  const handleContinue = () => {
    if (selectedRole) {
      onRoleSelect(selectedRole, shouldSaveAnswer);
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "400px" }}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          border: `1px solid ${palette.lightGray.main}`,
          padding: 3,
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="h1" color="text.primary">
            Welcome to Moxie!
          </Typography>
          <Typography variant="paragraphSmall" color="text.secondary">
            What is your role?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "236px",
            gap: 2,
          }}
        >
          <RoleCard
            selected={selectedRole === SignInUserRole.Staff}
            onClick={() => handleRoleSelect(SignInUserRole.Staff)}
            text="I work at a medspa"
          >
            <Image
              src="/assets/images/worker.svg"
              width={80}
              height={98}
              alt="Medical spa staff illustration"
              priority
            />
          </RoleCard>
          <RoleCard
            selected={selectedRole === SignInUserRole.Client}
            onClick={() => handleRoleSelect(SignInUserRole.Client)}
            text="I am a medspa's client"
          >
            <Image
              src="/assets/images/client.svg"
              width={72}
              height={96}
              alt="Medical spa client illustration"
              priority
            />
          </RoleCard>
        </Box>
        <Box sx={{ height: "1px", width: "100%", backgroundColor: GREY[20] }} />
        <FormLabel
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Typography
            variant="labelSmall"
            color="text.primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={shouldSaveAnswer}
              onChange={(e) => setShouldSaveAnswer(e.target.checked)}
            />
            Save my answer for the future
          </Typography>
        </FormLabel>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Button
            variant="contained"
            fullWidth
            sx={{ height: "40px" }}
            onClick={handleContinue}
            disabled={!selectedRole}
          >
            Continue
          </Button>
        </Box>
      </Card>
    </Box>
  );
}
