import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import theme from "@/config/mui/theme";
import ErrorCircleIconSvg from "../common/icons/errorCircleIconSvg";
import SuccessCircleIconSvg from "../common/icons/successCircleIconSvg";

const ERROR_TYPES = {
  CLIENT_NOT_FOUND: "client-not-found",
  TOO_MANY_ATTEMPTS: "too-many-attempts",
} as const;

const STATUS_MESSAGES = {
  SUCCESS: {
    title: "Link has been sent",
    main: "Check your email inbox to find login link(s) to your client portal(s).",
    secondary: "Feel free to close this tab",
    showRetry: false,
  },
  [ERROR_TYPES.CLIENT_NOT_FOUND]: {
    title: "Account not found",
    main: "We're unable to find a client account at any medspa using that email address.",
    secondary: "Please contact with your medspa to solve this.",
    showRetry: true,
  },
  [ERROR_TYPES.TOO_MANY_ATTEMPTS]: {
    title: "Too many attempts",
    main: "You've made too many login attempts. For security reasons, we need to temporarily restrict access.",
    secondary: "Please wait for a few seconds before trying again.",
    showRetry: true,
  },
  DEFAULT_ERROR: {
    title: "Something went wrong",
    main: "An unexpected error occurred while trying to send the login link.",
    secondary:
      "Please try again later or contact support if the problem persists.",
    showRetry: true,
  },
} as const;

const getStatusMessages = (
  error?: Error
): {
  title: string;
  main: string;
  secondary: string;
  showRetry: boolean;
} => {
  if (!error) {
    return STATUS_MESSAGES.SUCCESS;
  }

  switch (error?.message) {
    case ERROR_TYPES.CLIENT_NOT_FOUND:
      return STATUS_MESSAGES[ERROR_TYPES.CLIENT_NOT_FOUND];
    case ERROR_TYPES.TOO_MANY_ATTEMPTS:
      return STATUS_MESSAGES[ERROR_TYPES.TOO_MANY_ATTEMPTS];
    default:
      return STATUS_MESSAGES.DEFAULT_ERROR;
  }
};

type Props = {
  error?: Error;
  onTryWithDifferentEmail: () => void;
};

export function StatusCard({ error, onTryWithDifferentEmail }: Props) {
  const { title, main, secondary, showRetry } = getStatusMessages(error);

  return (
    <Card
      sx={{
        border: `1px solid ${theme.palette.grey[200]}`,
        p: 3,
        width: "100%",
        maxWidth: "420px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {error ? (
            <ErrorCircleIconSvg size="80" />
          ) : (
            <SuccessCircleIconSvg size="80" />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h3" color="text.primary">
            {title}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Typography color="text.secondary" variant="body1">
              {main}
            </Typography>
            <Typography color="text.secondary" variant="body1">
              {secondary}
            </Typography>
            {showRetry && (
              <Button
                variant="outlined"
                size="small"
                onClick={onTryWithDifferentEmail}
              >
                Try with a different email
              </Button>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default StatusCard;
