import { Button, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { ReactNode } from "react";
import { CheckSmallIcon } from "@/components/common/icons";
import { VIOLET, WHITE, GREY } from "@/config/mui/colorPalette";

type RoleCardProps = {
  selected?: boolean;
  onClick: () => void;
  children: ReactNode;
  text: string;
};

export function RoleCard({
  selected = false,
  onClick,
  children,
  text,
}: RoleCardProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        textAlign: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        onClick={onClick}
        component="div"
        sx={{
          border: `2px solid ${selected ? VIOLET[90] : "transparent"}`,
          borderRadius: "16px",
          flex: 1,
          padding: 1,
          backgroundColor: !selected ? GREY[10] : "transparent",
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
            border: `2px solid ${VIOLET[90]}`,
          },
          transition: theme.transitions.create([
            "border",
            "background-color",
            "opacity",
          ]),
          height: "100%",
          marginBottom: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: theme.spacing(1.5),
            left: theme.spacing(1.5),
            zIndex: 10,
            height: 20,
            width: 20,
            backgroundColor: VIOLET[90],
            borderRadius: "10px",
            opacity: selected ? 1 : 0,
            transform: selected ? "scale(1)" : "scale(0.8)",
            transition: theme.transitions.create(["opacity", "transform"]),
          }}
        >
          <CheckSmallIcon color={WHITE} size={20} />
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: selected ? VIOLET[20] : "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: theme.transitions.create("background-color"),
            height: "100%",
            width: "100%",
          }}
        >
          <Typography variant="h3">{children}</Typography>
        </Box>
      </Button>
      <Typography variant="labelSmall" color="text.primary">
        {text}
      </Typography>
    </Box>
  );
}
