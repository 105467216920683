import { Box, Link } from "@mui/material";

export type PoweredByMoxieProps = {
  medspaSlug?: string;
};

export const PoweredByMoxie = ({ medspaSlug }: PoweredByMoxieProps) => {
  const baseUrl = "https://www.joinmoxie.com";
  const params = new URLSearchParams({
    utm_source: "referral",
    utm_medium: "powered-by",
  });

  if (medspaSlug) {
    params.set("utm_campaign", medspaSlug);
  }

  const moxieReferralLink = `${baseUrl}?${params.toString()}`;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Link
        component="a"
        href={moxieReferralLink}
        variant="paragraphSmall"
        color="secondary.light"
        underline="hover"
        target="_blank"
        rel="noopener"
      >
        Powered by Moxie
      </Link>
    </Box>
  );
};
