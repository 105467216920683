type Props = {
  size?: string;
  XColor?: string;
  bgColor?: string;
};

export default function ErrorCircleIconSvg({
  size = "200",
  XColor = "#F21A06",
  bgColor = "#FCE6E3",
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 200 200"
    >
      <circle cx="100" cy="100" r="100" fill={bgColor} />
      <path
        fill={XColor}
        d="M126.631 65.253a7.692 7.692 0 0 1 0 10.879l-24.176 24.176 24.176 24.176a7.692 7.692 0 0 1 0 10.878c-1.707 1.707-6.209 2.253-6.209 2.253h15.384a7.692 7.692 0 0 0 5.44-13.131l-24.176-24.176 24.176-24.176A7.692 7.692 0 0 0 135.807 63h-14.581a7.667 7.667 0 0 1 5.405 2.253ZM76.576 63c1.968 0 3.937.751 5.439 2.253l16.869 16.869-7.308 7.307L67.4 65.253A7.668 7.668 0 0 0 62.043 63h14.533Zm-9.033 74.585c-1.742.009-3.536.018-5.186.02a7.66 7.66 0 0 0 5.043-2.243l24.176-24.176 7.308 7.308-16.869 16.868a7.673 7.673 0 0 1-5.823 2.244c-1.205-.06-4.795-.042-8.642-.022l-.007.001Z"
      />
      <path
        fill="#fff"
        d="M127.362 76.132a7.692 7.692 0 0 0-10.878-10.879L92.308 89.429 68.132 65.253a7.692 7.692 0 0 0-10.879 10.879l24.176 24.176-24.176 24.176a7.692 7.692 0 0 0 10.879 10.878l24.176-24.176 24.176 24.176a7.692 7.692 0 1 0 10.878-10.878l-24.176-24.176 24.176-24.176Z"
      />
      <path
        stroke="#491712"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M57.253 65.253A7.669 7.669 0 0 1 62.707 63h14.6c1.968 0 3.937.751 5.44 2.253l16.868 16.869 16.869-16.869m-59.231 0a7.692 7.692 0 0 0 0 10.879l24.176 24.176-24.176 24.176a7.692 7.692 0 0 0 0 10.878m0-70.109a7.692 7.692 0 0 1 10.879 0l24.176 24.176 24.176-24.176m-59.231 70.109a7.67 7.67 0 0 0 5.836 2.243c1.649-.002 3.443-.011 5.185-.02l.007-.001c3.847-.019 7.437-.038 8.642.022a7.673 7.673 0 0 0 5.824-2.244l16.868-16.868 16.869 16.868m-59.231 0a7.692 7.692 0 0 0 10.879 0l24.176-24.176 24.176 24.176m0 0a7.659 7.659 0 0 0 4.635 2.212l.034.041h15.385a7.692 7.692 0 0 0 5.439-13.131l-24.176-24.176 24.176-24.176A7.692 7.692 0 0 0 136.538 63h-14.595a7.668 7.668 0 0 0-5.459 2.253m0 70.109a7.692 7.692 0 1 0 10.878-10.878l-24.176-24.176 24.176-24.176a7.692 7.692 0 0 0-10.878-10.879"
      />
    </svg>
  );
}
