import { Box, Button, Typography, Avatar, Stack } from "@mui/material";
import Image from "next/image";
import { GREY } from "@/config/mui/colorPalette";
import useMoxieMediaQuery from "@/hooks/misc/useMoxieMediaQuery";
import { SignInUserRole } from "@/types/signIn";
import OptionMenuMobile from "./optionMenuMobile";

const ROLE_CONFIG: Record<
  SignInUserRole,
  { avatar: string; displayText: string }
> = {
  [SignInUserRole.Staff]: {
    avatar: "/assets/images/worker.svg",
    displayText: "I work at medspa",
  },
  [SignInUserRole.Client]: {
    avatar: "/assets/images/client.svg",
    displayText: "I am a medspa's client",
  },
};

type Props = {
  title: string;
  medspaName?: string;
  onChangeClick?: () => void;
  role?: SignInUserRole;
};

export default function MoxieLogoPageHeader({
  title,
  medspaName,
  onChangeClick,
  role,
}: Props) {
  const isMobile = useMoxieMediaQuery("ipad-hor", "down");
  const shouldShowRoleChip = role && onChangeClick && !isMobile;

  return (
    <Box
      sx={{
        width: "100%",
        height: "56px",
        display: "flex",
        alignItems: "center",
        px: {
          xs: 2,
          sm: 4.75,
        },
        backgroundColor: "background.paper",
        borderBottom: 1,
        borderColor: "lightGray.main",
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            gap: 2,
          }}
        >
          <MoxieLogo medspaName={medspaName} />
          <Box
            sx={{
              height: "16px",
              width: "1px",
              backgroundColor: GREY[20],
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          />
          <Typography
            variant="paragraphSmall"
            color="text.secondary"
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          >
            {title}
          </Typography>
        </Stack>
        {isMobile && role && onChangeClick && (
          <OptionMenuMobile role={role} onChangeClick={onChangeClick} />
        )}
        {shouldShowRoleChip && (
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              gap: 2,
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                gap: 1,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: GREY[10],
                  "& .MuiAvatar-img": {
                    objectFit: "contain",
                    marginTop: "25%",
                  },
                }}
                sizes="sm"
                src={ROLE_CONFIG[role]?.avatar}
                alt={`${ROLE_CONFIG[role]?.displayText} role icon`}
              />
              <Typography variant="labelSmall">
                {ROLE_CONFIG[role]?.displayText}
              </Typography>
            </Stack>
            <Box
              sx={{
                height: "16px",
                width: "1px",
                backgroundColor: GREY[20],
                display: {
                  xs: "none",
                  sm: "block",
                },
              }}
            />
            <Button variant="text" size="small" onClick={onChangeClick}>
              Change
            </Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

function MoxieLogo({ medspaName }: { medspaName?: string }) {
  if (!medspaName) {
    return (
      <Image
        src="/assets/moxie-logo.svg"
        width={96}
        height={24}
        alt="Moxie logo"
        priority
      />
    );
  }
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        gap: 1,
      }}
    >
      <Image
        src="/assets/icons/icon-48x48.svg"
        width={40}
        height={40}
        alt="Moxie logo"
      />
      {medspaName && (
        <Typography variant="subtitleDefault">{medspaName}</Typography>
      )}
    </Stack>
  );
}
