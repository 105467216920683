import { SignIn } from "@clerk/nextjs";
import { Box, useTheme } from "@mui/material";
import { GREY, VIOLET } from "@/config/mui/colorPalette";

export default function ClerkSignIn() {
  const { palette, typography, spacing } = useTheme();
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <SignIn
        appearance={{
          layout: {
            socialButtonsPlacement: "bottom",
          },
          elements: {
            // Layout elements
            card: {
              padding: spacing(3),
            },
            cardBox: {
              border: `1px solid ${palette.lightGray.main}`,
              boxShadow: "none",
            },

            // Header elements
            header: {
              alignItems: "flex-start",
            },
            headerTitle: {
              fontSize: typography.h1.fontSize,
              textAlign: "left",
            },
            headerSubtitle: {
              fontSize: typography.body1.fontSize,
              fontWeight: typography.body1.fontWeight,
              textAlign: "left",
            },
            identityPreview: {
              width: "100%",
              backgroundColor: GREY[5],
              borderRadius: "12px",
              justifyContent: "space-between",
              padding: spacing(1.25, 1.5),
              height: "auto",
              marginTop: spacing(1),
            },
            identityPreviewEditButton: {
              height: "auto",
            },
            identityPreviewText: {
              fontSize: typography.body2.fontSize,
              fontWeight: typography.body2.fontWeight,
            },
            logoBox: {
              display: "none",
            },

            // Form elements
            button: {
              fontSize: typography.buttonSmall.fontSize,
              borderRadius: "12px",
              height: spacing(5),
            },
            alternativeMethodsBlockButton: {
              backgroundColor: VIOLET[90],
              fontSize: typography.buttonSmall.fontSize,
              fontWeight: typography.buttonSmall.fontWeight,
              boxShadow: "none",

              "&:hover": {
                backgroundColor: VIOLET[100],
              },

              "&::after": {
                display: "none",
              },
            },
            formButtonPrimary: {
              backgroundColor: VIOLET[90],
              fontSize: typography.buttonSmall.fontSize,
              fontWeight: typography.buttonSmall.fontWeight,
              boxShadow: "none",

              "&:hover": {
                backgroundColor: VIOLET[100],
              },

              "&::after": {
                display: "none",
              },
            },
            form: {
              gap: spacing(2),
            },
            input: {
              height: spacing(5),
              borderRadius: "12px",

              "&::placeholder": {
                fontSize: typography.body2.fontSize,
                fontWeight: typography.body2.fontWeight,
              },
            },
            buttonArrowIcon: {
              display: "none",
            },
            formFieldLabel: {
              fontSize: typography.labelSmall.fontSize,
              fontWeight: typography.labelSmall.fontWeight,
            },
            socialButtonsBlockButtonText: {
              fontSize: typography.buttonSmall.fontSize,
            },

            // Footer
            footer: {
              display: "none",
            },
          },
        }}
      />
    </Box>
  );
}
