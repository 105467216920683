import { Box, Card, Typography, useTheme } from "@mui/material";
import { FormTextField } from "@/components/serviceFlow/clientDetails/forms/formFields";
import { useClientPortalForm } from "@/hooks/signin/useClientPortalForm";
import LoadingButton from "../common/buttons/loadingButton";
import { StatusCard } from "./statusCard";

export default function GetClientPortalLinkCard() {
  const {
    control,
    setValue,
    errors,
    isValid,
    isLoading,
    linkSent,
    error,
    handleAction,
    resetForm,
  } = useClientPortalForm();

  const { palette } = useTheme();

  if (linkSent || error) {
    return <StatusCard error={error} onTryWithDifferentEmail={resetForm} />;
  }

  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          border: `1px solid ${palette.lightGray.main}`,
          p: 3,
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (isValid) handleAction();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant="h1" color="text.primary">
              Go to client portal
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Type your email below to receive a login link to your
              medspa&apos;s client portal
            </Typography>
          </Box>

          <FormTextField
            control={control}
            name="value"
            label="Enter email"
            setValue={setValue}
            error={Boolean(errors.value)}
            helperText={errors.value?.message}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ height: 44 }}
            disabled={!isValid}
            loading={isLoading}
          >
            Get client portal link
          </LoadingButton>
        </form>
      </Card>
    </Box>
  );
}
