import type { GetServerSidePropsContext, NextPage } from "next";
import { SignInUserRole } from "@/types/signIn";
import SignInView, { SELECTED_ROLE_COOKIE_KEY } from "@/views/signInView";

const SignIn: NextPage<{
  selectedRoleValueFromCookie?: SignInUserRole;
}> = ({ selectedRoleValueFromCookie }) => {
  return (
    <SignInView selectedRoleValueFromCookie={selectedRoleValueFromCookie} />
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const selectedRoleValueFromCookie =
    context?.req?.cookies[SELECTED_ROLE_COOKIE_KEY];

  return {
    props: selectedRoleValueFromCookie ? { selectedRoleValueFromCookie } : {},
  };
};

export default SignIn;
